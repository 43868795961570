import React from "react";
import "./Button.css";

export default function Button (props) {
    let {className, text, displayRandomQuestion} = props;

    return (
        <button className={`${className}`} onClick={displayRandomQuestion}>
            {text}
        </button>
    );
}


Button.defaultProps = {className: "button", text: "button"}
