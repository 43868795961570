import React from "react";
import "./AnimatedCupofTea.css";

export default function AnimatedCupOfTea () {
    return (
        <div className="cup-wrap">
            <div className="cup">
                <img src="images/cup.png" alt="cup"/>
            </div>
            <div className="smoke-wrap">
                <img className="smoke" src="images/smoke.png" alt="smoke"/>
            </div>
            <div className="smoke-wrap">
                <img className="smoke2" src="images/smoke.png" alt="smoke"/>
            </div>
            <div className="smoke-wrap">
                <img className="smoke3" src="images/smoke--grey.png" alt="smoke"/>
            </div>
        </div>
    );
}
