import React from "react";
import "./TeaLabel.css";

export default function TeaLabel (props) {
    let {question} = props;

    return (
        <div className="tealabel">
            <div className="text">
                <p>{question.question}</p>
            </div>
        </div>
    );
}
