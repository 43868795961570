import React, {useState} from "react";
import "./App.css";
import Button from "./Button/Button"
import AnimatedCupOfTea from "./AnimatedCupOfTea/AnimatedCupOfTea";
import TeaLabel from "./TeaLabel/TeaLabel";
import {questions} from "./questions";

export default function App () {
    let currentQuestion = questions[Math.floor(Math.random() * questions.length)];
    const [unaskedQuestions, setUnaskedQuestions] = useState(questions.filter(a =>
        a.id !== currentQuestion.id
    ));
    const [questionsAsked, setQuestionsAsked] = useState([currentQuestion]);
    const [question, setQuestion] = useState(currentQuestion);

    function displayRandomQuestion () {
        let newQuestion = unaskedQuestions[Math.floor(Math.random() * unaskedQuestions.length)];

        questionsAsked.push(newQuestion);
        setQuestionsAsked(questionsAsked);

        setUnaskedQuestions(unaskedQuestions.filter(a =>
            a.id !== newQuestion.id
        ));

        setQuestion(newQuestion);

        if (unaskedQuestions.length === 1) {
            setUnaskedQuestions(questions);
            setQuestionsAsked([]);
        }
    }

    return (
        <div className="app">
            <h1 className="title">Theevraag.nl</h1>
            <div className="description">
                <p>Wel een theetje maar geen vraag aan het zakje? Geen nood!</p>
                <p>Wij helpen je verder. Met {questions.length} vragen van <b>Theevraag</b> kan je lekker je theetje
                    drinken en de vraag
                    stellen die je misschien zelf niet durfde te stellen.</p>
            </div>
            <div className="button__wrapper">
                <Button text='Toon een nieuwe vraag' displayRandomQuestion={displayRandomQuestion}/>
            </div>
            <div className="teaLabel__wrapper">
                <TeaLabel question={question}/>
            </div>
            <div className="animated-cup-of-tea__wrapper">
                <AnimatedCupOfTea/>
            </div>
        </div>
    );
}

